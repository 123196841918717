/* Include theme styles for core and each component used in your app.
 Alternatively, you can import and @include the theme mixins for each component
 that you are using. */

/* You can add global styles to this file, and also import other style files */

@import "./assets/fonts.scss";
@import "./assets/lcptracker-theme.scss";

/* @tailwind base; */
@tailwind components;
@tailwind utilities;

/* :host, :root {
  --rem: 14px;
} */

:root {
  font-size: 14px;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;

  // p{
  //   font-size: 1rem!important;
  // }
}

.mat-mdc-checkbox label {
  font-size: 1rem;
}

.mat-mdc-checkbox {
  font-family: "Poppins", sans-serif !important;
}

/*Custom Style After Angular 16 Upgrade*/
.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
  line-height: inherit !important;
}

.mat-mdc-unelevated-button > .mat-icon {
  font-size: 1.28rem !important;
  height: 1.28rem !important;
  width: 1.28rem !important;
}

.vtg-box-shadow {
  box-shadow: 0 3px 4px #0000001f;
}

.vtg-size-icon{
  font-size: 1.28rem !important;
  height: 1.28rem !important;
  width: 1.28rem !important;
}