/* src/styles/fonts.scss */

@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src: url('../assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url('../assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: url('../assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Oswald';
  font-weight: 200;
  src: url('../assets/fonts/Oswald/static/Oswald-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Oswald';
  font-weight: 300;
  src: url('../assets/fonts/Oswald/static/Oswald-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Oswald';
  font-weight: 400;
  src: url('../assets/fonts/Oswald/static/Oswald-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Oswald';
  font-weight: 500;
  src: url('../assets/fonts/Oswald/static/Oswald-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Oswald';
  font-weight: 600;
  src: url('../assets/fonts/Oswald/static/Oswald-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Oswald';
  font-weight: 700;
  src: url('../assets/fonts/Oswald/static/Oswald-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 100;
  src: url('../assets/fonts/Montserrat/static/Montserrat-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 200;
  src: url('../assets/fonts/Montserrat/static/Montserrat-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  src: url('../assets/fonts/Montserrat/static/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: url('../assets/fonts/Montserrat/static/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: url('../assets/fonts/Montserrat/static/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: url('../assets/fonts/Montserrat/static/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 700;
  src: url('../assets/fonts/Montserrat/static/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 800;
  src: url('../assets/fonts/Montserrat/static/Montserrat-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 900;
  src: url('../assets/fonts/Montserrat/static/Montserrat-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 100;
  src: url('../assets/fonts/Poppins/Poppins-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 200;
  src: url('../assets/fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  src: url('../assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  src: url('../assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  src: url('../assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  src: url('../assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  src: url('../assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 800;
  src: url('../assets/fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 900;
  src: url('../assets/fonts/Poppins/Poppins-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-weight: 100;
  src: url('../assets/fonts/Fira_Sans_Extra_Condensed/FiraSansExtraCondensed-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-weight: 200;
  src: url('../assets/fonts/Fira_Sans_Extra_Condensed/FiraSansExtraCondensed-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-weight: 300;
  src: url('../assets/fonts/Fira_Sans_Extra_Condensed/FiraSansExtraCondensed-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-weight: 400;
  src: url('../assets/fonts/Fira_Sans_Extra_Condensed/FiraSansExtraCondensed-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-weight: 500;
  src: url('../assets/fonts/Fira_Sans_Extra_Condensed/FiraSansExtraCondensed-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-weight: 600;
  src: url('../assets/fonts/Fira_Sans_Extra_Condensed/FiraSansExtraCondensed-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-weight: 700;
  src: url('../assets/fonts/Fira_Sans_Extra_Condensed/FiraSansExtraCondensed-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-weight: 800;
  src: url('../assets/fonts/Fira_Sans_Extra_Condensed/FiraSansExtraCondensed-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Fira Sans Extra Condensed';
  font-weight: 900;
  src: url('../assets/fonts/Fira_Sans_Extra_Condensed/FiraSansExtraCondensed-Black.ttf') format('truetype');
}